<template>
  <div class="basic-info">
    <ConfigurationHeaderContainer title="General Info for Project X"></ConfigurationHeaderContainer>
    {{ projectNumber }}
    <!-- <div style="display: flex; justify-content: space-between;">
      <div>
        <kendo-button class="k-button" :ref="'downloadConfiguration'" :disabled="downloadConfigurationDisabled" @click="downloadConfigurationTemplate">Download configuration template</kendo-button>
      </div>
      <div>
        <button class="k-button" @click="downloadConfigurationTemplate" disabled="disabled">Save</button>
        <button class="k-button" @click="importConfigurationTemplate">Cancel</button>
      </div>
    </div> -->
    <div style="display:flex; flex-direction: row;">
      <div>
        <FormEntryContainer title="Project Number" type="text" size="10" :value="projectNumber"></FormEntryContainer>
        <FormEntryContainer title="Name" type="text" size="100"></FormEntryContainer>
        <FormEntryContainer title="Project Lead (PI)" type="text" size="50"></FormEntryContainer>
        <FormEntryContainer title="Client" type="text" size="50"></FormEntryContainer>
        <div class="form-entry-container">
          <div class="form-entry-label">Review Type</div>
          <div class="form-entry-input">
            <kendo-dropdownlist :data-source="reviewTypeValues" :options-label="'Select field...'" :data-text-field="'ReviewTypeName'" :data-value-field="'ReviewTypeId'" :value="1" class="exceptionListWidth" :change="selectReviewType"></kendo-dropdownlist>
          </div>
        </div>
        <div class="form-entry-container" v-if="disabledValue">
          <div class="form-entry-label">Citations exempt from double review</div>
          <div class="form-entry-input">
            Citations where:
            <table>
              <tr>
                <td><b>Field</b></td>
                <td><b>Operator</b></td>
                <td><b>Value</b></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td><kendo-dropdownlist :data-source="fieldValues" :options-label="'Select field...'" :data-text-field="'FieldName'" :data-value-field="'FieldId'" :value="5" class="exceptionListWidth"></kendo-dropdownlist></td>
                <td><kendo-dropdownlist :data-source="operatorValues" :options-label="'Select operator...'" class="exceptionListWidth"></kendo-dropdownlist></td>
                <td><kendo-dropdownlist :data-source="databaseValues" :options-label="'Select Database...'" :data-text-field="'DatabaseName'" :data-value-field="'DatabaseId'" :value="4" class="exceptionListWidth"></kendo-dropdownlist></td>
                <td><kendo-dropdownlist :data-source="logicValues" :options-label="'Select logic...'" :data-text-field="'LogicName'" :data-value-field="'LogicId'" :value="2"></kendo-dropdownlist></td>
                <td><kendo-button class="k-button" @click="plusActionEvent">+</kendo-button><kendo-button class="k-button" @click="removeActionEvent">-</kendo-button></td>
              </tr>
              <tr v-if="plusAction">
                <td><kendo-dropdownlist :data-source="fieldValues" :options-label="'Select field...'" :data-text-field="'FieldName'" :data-value-field="'FieldId'" :value="1" class="exceptionListWidth"></kendo-dropdownlist></td>
                <td><kendo-dropdownlist :data-source="operatorValues" :options-label="'Select operator...'" class="exceptionListWidth"></kendo-dropdownlist></td>
                <td><kendo-dropdownlist :data-source="reviewerValues" :options-label="'Select Reviewer...'" class="exceptionListWidth"></kendo-dropdownlist></td>
                <td><kendo-dropdownlist :data-source="logicValues" :options-label="'Select logic...'" :data-text-field="'LogicName'" :data-value-field="'LogicId'" :value="2"></kendo-dropdownlist></td>
                <td><kendo-button class="k-button" @click="plusActionEvent">+</kendo-button><kendo-button class="k-button" @click="removeActionEvent">-</kendo-button></td>
              </tr>
            </table>
          </div>
        </div>
        <FormEntryContainer title="Restrict study mapping until citation has been fully reviewed?" type="checkbox" size="50"></FormEntryContainer>
        <FormEntryContainer title="Allow review validation?" type="checkbox" size="50"></FormEntryContainer>
      </div>
      <div>
        <FormEntryContainer title="Description" type="textarea" cols="200" rows="10"></FormEntryContainer>
        <FormEntryContainer title="Project Type" type="dropdown" :datasource="this.projectTypeDataSource" :valuefield="'ProjectTypeIdentity'" :textfield="'ProjectTypeName'" value="1"></FormEntryContainer>
        <FormEntryContainer title="Project Status" type="dropdown" :datasource="this.projectStatusDataSource" :valuefield="'ProjectStatusIdentity'" :textfield="'ProjectStatusName'" value="1"></FormEntryContainer>
        <FormEntryContainer title="Data Extraction Type" type="dropdown" :datasource="this.dataExtractionValues" size="10"></FormEntryContainer>
        <FormEntryContainer title="Restrict form data extraction if citations have been fully reviewed?" type="checkbox" size="50"></FormEntryContainer>
      </div>
    </div>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import FormEntryContainer from '@/containers/FormEntryContainer'
import ProjectTypeDataSource from '@/assets/data/ProjectType.json'
import ProjectStatusDataSource from '@/assets/data/ProjectStatus.json'
import SearchStrategyDataSource from '@/assets/data/SearchStrategy.json'

export default {
  name: 'basic-info',
  props: ['ProjectNumber', 'ProjectDescription', 'ProjectType', 'ProjectStatus', 'ProjectLead', 'ProjectClient'],
  components: {
    ConfigurationHeaderContainer,
    FormEntryContainer
  },
  data () {
    return {
      plusAction: false,
      disabledValue: false,
      projectNumber: this.ProjectNumber,
      downloadConfigurationDisabled: false,
      importConfigurationDisabled: true,
      projectTypeDataSource: ProjectTypeDataSource,
      projectStatusDataSource: ProjectStatusDataSource,
      searchStrategyDataSource: SearchStrategyDataSource,
      reviewTypeValues: [
        { ReviewTypeId: 1, ReviewTypeName: 'Single Review' },
        { ReviewTypeId: 2, ReviewTypeName: 'Double Review' }
      ],
      dataExtractionValues: ['No extraction', 'Single extraction', 'Double extraction'],
      fieldValues: [
        { FieldId: 1, FieldName: 'Author' },
        { FieldId: 2, FieldName: 'Year' },
        { FieldId: 3, FieldName: 'Reviewer' },
        { FieldId: 4, FieldName: 'Stage' },
        { FieldId: 5, FieldName: 'Database Name' }
      ],
      databaseValues: [
        { DatabaseId: 1, DatabaseName: 'Embase' },
        { DatabaseId: 2, DatabaseName: 'CCTR' },
        { DatabaseId: 3, DatabaseName: 'MEDLINE' },
        { DatabaseId: 4, DatabaseName: 'Northern Light' }
      ],
      reviewerValues: ['Luzardo, Ricardo', 'Carmichael, Kevin'],
      operatorValues: ['Is equal to', 'Is not equal to', 'Starts with', 'Contains', 'Does not contain', 'Ends with', 'Is null', 'Is not null', 'Is empty', 'Is not empty', 'Has no value', 'Has value'],
      logicValues: [
        { LogicId: 1, LogicName: 'AND' },
        { LogicId: 2, LogicName: 'OR' },
        { LogicId: 3, LogicName: 'XOR' }
      ]
    }
  },
  methods: {
    plusActionEvent: function () {
      this.plusAction = true
    },
    removeActionEvent: function () {
      this.plusAction = false
    },
    selectReviewType: function (e) {
      if (e.sender.select() === 0) {
        this.disabledValue = false
      } else {
        this.disabledValue = true
      }
      console.log(this.disabledValue)
    },
    changeDataEntry: function () {
      this.$store.state.isDoubleDataEntry = !this.$store.state.isDoubleDataEntry
    },
    changeActionVisualization: function () {
      this.$store.state.isFirstActionVisualization = !this.$store.state.isFirstActionVisualization
    }
  },
  mounted: function () {
  }
}
</script>
<style>
.container {
  display: table;
  padding-left: 0px;
}
.row {
  display: table-row;
}
.left, .right {
  display: table-cell;
}
.inner-column-container {
  display: flex;
  flex-direction: column;
}

.k-list {
  font-size: 1.5em !important;
  width: 100px;
}

.k-input {
  font-size: 1.5em !important;
  width: 900px;
}
.exceptionListWidth {
  width: 100px;
  padding-right: 10px;
}
</style>
